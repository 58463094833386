import { useTranslation } from 'react-i18next';
import Accordion from './Accordion';

const Faq = () => {
  const { t } = useTranslation();

  const buildFAQData = () => {
    const FAQData = [];
    for (let index = 1; index <= 6; index++) {
      const FAQ = {
        question: t(`landing.faq.question${index}`),
        answer: t(`landing.faq.answer${index}`),
      };

      FAQData.push(FAQ);
    }
    return FAQData;
  };

  return (
    <>
      <h2 className="fo-display-2 text-center mb-10">{t('landing.faq.title')}</h2>
      <Accordion FAQData={buildFAQData()} />
    </>
  );
};

export default Faq;
