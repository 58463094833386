import { useTranslation } from 'react-i18next';
import { ButtonLink } from '~/components/common/buttons/ButtonLink';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import mixpanelTrack from '~/hooks/useMixPanel';

const CHECK_CIRCLE_COLOR = '#32AC55';

const HostFocus = () => {
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden">
      <div className="host-focus row g-8 g-lg-24">
        <div className="col-12 col-md-6 order-1 order-md-0">
          <div className="left-panel rounded-4 p-6 p-sm-10">
            <div className="rounded-4 bg-white py-8 px-6 px-md-12">
              <h2 className="text-center mb-6 title">{t('landing.hostFocus.leftPanel.title')}</h2>
              <div className="pt-6 fo-border-top-sm border-grey-200">
                <div className="d-flex justify-content-between mb-6">
                  <div className="graphic-description me-4">{t('landing.hostFocus.leftPanel.description')}</div>
                  <div className="text-nowrap">
                    <SVGIcon
                      iconType="check-circle"
                      fill={CHECK_CIRCLE_COLOR}
                    ></SVGIcon>
                    <span className="graphic-description">+15%</span>
                  </div>
                </div>
                <img
                  className="w-100"
                  src={`/media-remix/host-focus-graphic.svg`}
                  alt="Graphic representing generated revenu over 6 months"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="right-panel">
            <h2 className="fo-display-2 -semibold">
              {t('landing.hostFocus.rightPanel.titleBeforeHighlight')}{' '}
              <span className="-highlight"> {t('landing.hostFocus.rightPanel.titleAfterHighlight')}</span>
            </h2>
            <div className="text-1 color-grey-500 mb-8">{t('landing.hostFocus.rightPanel.subtitle')}</div>
            <div className="testimonial-card p-6 rounded-4">
              <p className="color-grey-600 mb-3">“{t('landing.hostFocus.rightPanel.testimonial.text')}”</p>
              <div className="fo-display-5">{t('landing.hostFocus.rightPanel.testimonial.source')}</div>
            </div>
            <div className="d-none d-md-flex justify-content-end mt-12">
              <ButtonLink
                variant="primaryOutline"
                size="xl"
                to={t('global.linkPaths.publishAnOffice')}
                target="_blank"
                rel="noreferrer"
                onClick={() => mixpanelTrack('0.list_your_office_hpstrip')}
              >
                {t('landing.hostFocus.cta')}
                <SVGIcon
                  iconType={'long-arrow-right'}
                  className="ms-2"
                />
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
      <div className="d-grid d-md-none mt-8">
        <ButtonLink
          variant="primaryOutline"
          size="xl"
          to={t('global.linkPaths.publishAnOffice')}
          target="_blank"
          rel="noreferrer"
          onClick={() => mixpanelTrack('0.list_your_office_hpstrip')}
        >
          {t('landing.hostFocus.cta')}
          <SVGIcon
            iconType={'long-arrow-right'}
            className="ms-2"
          />
        </ButtonLink>
      </div>
    </div>
  );
};

export default HostFocus;
