import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~/components/common/buttons/Button';
import SVGIcon from '~/components/ui/Icons/SVGIcon';

const Testimonials = () => {
  const { t } = useTranslation();
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window === 'undefined' || !carouselRef.current) return;

    const initCarousel = () => {
      if (window.bootstrap && window.bootstrap.Carousel) {
        new window.bootstrap.Carousel(carouselRef.current);
      }
    };

    initCarousel();
  }, [carouselRef]);

  return (
    <div className="testimonials w-100 bg-accent-500">
      <div className="container fixed-container-md ">
        <div
          ref={carouselRef}
          id="testimonialsCarousel"
          className="carousel slide"
        >
          <div className="carousel-indicators pb-4">
            <button
              type="button"
              data-bs-target="#testimonialsCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#testimonialsCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#testimonialsCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="d-flex justify-content-center">
            <div className="carousel-inner-wrapper px-md-36">
              <div className="carousel-inner pt-36">
                <div className="carousel-item active">
                  <div className="d-block w-100 text-center color-white">
                    <p className="fo-display-4 -regular mb-12 color-white">
                      “{t('landing.testimonials.testimonial1.text')}”
                    </p>
                    <div className="fo-display-3 -medium mb-1"> {t('landing.testimonials.testimonial1.name')}</div>
                    <div className="text-1 -regular"> {t('landing.testimonials.testimonial1.function')}</div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="d-block w-100 text-center color-white">
                    <p className="fo-display-4 -regular mb-12 color-white">
                      “{t('landing.testimonials.testimonial2.text')}”
                    </p>
                    <div className="fo-display-3 -medium mb-1"> {t('landing.testimonials.testimonial2.name')}</div>
                    <div className="text-1 -regular"> {t('landing.testimonials.testimonial2.function')}</div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="d-block w-100 text-center color-white">
                    <p className="fo-display-4 -regular mb-12 color-white">
                      “{t('landing.testimonials.testimonial3.text')}”
                    </p>
                    <div className="fo-display-3 -medium mb-1"> {t('landing.testimonials.testimonial3.name')}</div>
                    <div className="text-1 -regular"> {t('landing.testimonials.testimonial3.function')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-control-prev">
            <Button
              extraClasses="d-none d-md-block"
              size="xl"
              variant="primaryWhiteOutline"
              isIconButton={true}
              dataAttributes={{ 'data-bs-target': '#testimonialsCarousel', 'data-bs-slide': 'prev' }}
            >
              <SVGIcon iconType="long-arrow-left" />
            </Button>
          </div>
          <div className="carousel-control-next">
            <Button
              extraClasses="d-none d-md-block"
              size="xl"
              variant="primaryWhiteOutline"
              isIconButton={true}
              dataAttributes={{ 'data-bs-target': '#testimonialsCarousel', 'data-bs-slide': 'next' }}
            >
              <SVGIcon iconType="long-arrow-right" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testimonials;
