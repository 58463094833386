import { useTranslation } from 'react-i18next';
import { ButtonLink } from '~/components/common/buttons/ButtonLink';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import mixpanelTrack from '~/hooks/useMixPanel';

const Advantages = () => {
  const { t } = useTranslation();

  return (
    <div className="advantages">
      <h2 className="fo-display-2 text-center mb-4">{t('landing.advantages.title')}</h2>
      <p className="text-center mb-14">{t('landing.advantages.subtitle')}</p>
      <div className="row g-8">
        <div className="col-12 col-md-6 col-lg-3">
          <div className="advantage-card h-100 p-6">
            <img
              className="mb-4"
              src="/media-remix/icons/building.svg"
              alt="Rate icon"
            />
            <h3 className="fo-display-4 mb-2">{t('landing.advantages.a1.title')}</h3>
            <p className="">{t('landing.advantages.a1.description')}</p>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3">
          <div className="advantage-card h-100 p-6">
            <img
              className="mb-4"
              src="/media-remix/icons/stats.svg"
              alt="Rate icon"
            />
            <h3 className="fo-display-4 mb-2">{t('landing.advantages.a2.title')}</h3>
            <p>{t('landing.advantages.a2.description')}</p>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3">
          <div className="advantage-card h-100 p-6">
            <img
              className="mb-4"
              src="/media-remix/icons/contract.svg"
              alt="Rate icon"
            />
            <h3 className="fo-display-4 mb-2">{t('landing.advantages.a3.title')}</h3>
            <p>{t('landing.advantages.a3.description')}</p>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-3">
          <div className="advantage-card h-100 p-6">
            <img
              className="mb-4"
              src="/media-remix/icons/expand.svg"
              alt="Rate icon"
            />
            <h3 className="fo-display-4 mb-2">{t('landing.advantages.a4.title')}</h3>
            <p>{t('landing.advantages.a4.description')}</p>
          </div>
        </div>
      </div>
      <div className="d-grid d-md-flex justify-content-md-center mt-14">
        <ButtonLink
          variant="primaryOutline"
          size="xl"
          to={t('global.linkPaths.aboutUs')}
          reloadDocument={true}
          onClick={() => {
            mixpanelTrack('0.see_offices_advantage');
          }}
        >
          {t('global.links.aboutUs')}
          <SVGIcon
            iconType="long-arrow-right"
            className="ms-2"
          />
        </ButtonLink>
      </div>
    </div>
  );
};

export default Advantages;
