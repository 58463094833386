import { useInView } from 'react-intersection-observer';
import Hero from './Hero';
import TopMenu from '~/components/layouts/navigation/TopMenu';
import LoginModal from '~/components/layouts/navigation/LoginModal';
import AccountModal from '~/components/layouts/navigation/bottomMenu/AccountModal';
import BottomMenu from '~/components/layouts/navigation/bottomMenu/BottomMenu';
import { useUser } from '~/modules/useUser';
import CompanyModal from '~/components/layouts/navigation/CompanyModal';

const HomeNavbarWrapper = () => {
  const { ref, inView } = useInView({ initialInView: true, threshold: 0.0 });
  const clientStore = useUser();

  const homeNavConfig = {
    showDesktopSearch: !inView,
    showMobileSearch: !inView,
    showMobileHeader: true,
    onHero: inView,
    showBottomNav: true,
  };

  return (
    <>
      <div className={homeNavConfig.onHero ? '' : 'fixed-top'}>
        <TopMenu configuration={homeNavConfig} />
      </div>
      <div
        ref={ref}
        className={inView ? '' : 'pt-19 pt-md-22'}
      >
        <Hero />
      </div>
      {homeNavConfig.showBottomNav && <BottomMenu />}
      <LoginModal />
      <CompanyModal />
      {clientStore !== null && <AccountModal />}
    </>
  );
};

export default HomeNavbarWrapper;
