import { useState } from 'react';
import type { IconType } from '../../ui/Icons/Icons';
import SVGIcon from '../../ui/Icons/SVGIcon';
import type { ButtonWrapperProps } from './ButtonWrapper';
import ButtonWrapper from './ButtonWrapper';

type LoaderButtonProps = {
  btnText: string;
  iconType: IconType;
} & ButtonWrapperProps;

const LoaderButton = ({
  btnText,
  iconType,
  to,
  target,
  variant,
  extraClasses = '',
  size,
  type,
  onClick,
}: LoaderButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    if (onClick) {
      onClick();
    }
  };

  const ButtonWrapperProps = to
    ? {
        to,
        target,
        variant,
        extraClasses: `${extraClasses} ${loading ? 'disabled' : ''}`,
        size,
        onClick: () => handleClick(),
      }
    : {
        type,
        variant,
        extraClasses: `${extraClasses} ${loading ? 'disabled' : ''}`,
        size,
        onClick: () => handleClick(),
      };

  return (
    <>
      <ButtonWrapper {...ButtonWrapperProps}>
        {btnText}
        {!!iconType && !loading && (
          <SVGIcon
            iconType={iconType}
            className="ms-2"
          />
        )}
        {loading && <div className="ms-2 fo-spinner" />}
      </ButtonWrapper>
    </>
  );
};

export default LoaderButton;
