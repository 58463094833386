import { useTranslation } from 'react-i18next';

const Press = () => {
  const { t } = useTranslation();
  return (
    <div className="press">
      <h2 className="title text-center fo-display-2 -semibold mb-14">
        {t('landing.press.titleBeforeHighlight')}
        <br />
        <span className="-highlight">{t('landing.press.titleAfterHighlight')}</span>
      </h2>
      <div className="row g-8">
        <div className="col-12 col-md-6">
          <div className="press-card rounded-4 p-6">
            <p className="mb-8">“{t('landing.press.card1.text')}”</p>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="fo-display-5 -medium">{t('landing.press.card1.company')}</div>
                <div className="text-2 -regular color-grey-500">{t('landing.press.card1.date')}</div>
              </div>
              <img
                src={`/media-remix/press/maddyness.svg`}
                alt="Madyness logo"
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="press-card rounded-4 p-6">
            <p className="mb-8">“{t('landing.press.card2.text')}”</p>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="fo-display-5 -medium">{t('landing.press.card2.company')}</div>
                <div className="text-2 -regular color-grey-500">{t('landing.press.card2.date')}</div>
              </div>
              <img
                src={`/media-remix/press/BFM.svg`}
                alt="BFM Business logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Press;
