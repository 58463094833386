import { SupportedLanguage } from '~/config/i18n';

export const getJsonLd = (lang: SupportedLanguage) => {
  const jsonData = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    name: 'Fiveoffices',
    url: `https://www.fiveoffices.com/${lang}`,
    potentialAction: {
      '@type': 'SearchAction',
      target: `https://www.fiveoffices.com/${lang}/search/{search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };

  return jsonData;
};
