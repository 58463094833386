import { useTranslation } from 'react-i18next';
import ClientLogos from '~/components/common/ClientLogos';

const Clients = () => {
  const { t } = useTranslation();

  return (
    <div className="py-8">
      <h2 className="fo-display-5 color-grey-500 -medium text-center mb-10">{t('landing.clients.title')}</h2>
      <div className="fixed-container-md">
        <ClientLogos />
      </div>
    </div>
  );
};

export default Clients;
