import type { LoaderFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import { useLoaderData, useNavigate } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '~/api';

import i18next from '~/services/i18next.server';
import type { OfficeListType, DisplayOfficeSectionsType } from '~/types/offices';
import { useEffect } from 'react';
import mixpanelTrack from '~/hooks/useMixPanel';
import Footer from '~/components/layouts/footer/Footer';
import Faq from '~/components/pages/home/Faq';
import HomeNavbarWrapper from '~/components/pages/home/HomeNavbarWrapper';

import { tokenStringFromCookieHeader } from '~/services/session.server';
import OfficeCarousel from '~/components/common/office/OfficeCarousel';
import HowItWorks from '~/components/pages/home/HowItWorks';
import DNA from '~/components/pages/home/DNA';
import Clients from '~/components/pages/home/Clients';

import Advantages from '~/components/pages/home/Advantages';
import Testimonials from '~/components/pages/home/Testimonials';
import HostFocus from '~/components/pages/home/HostFocus';
import Press from '~/components/pages/home/Press';
import LoaderButton from '~/components/common/buttons/LoaderButton';
import { DEFAULT_LANGUAGE } from '~/config/i18n';
import { getJsonLd } from '~/components/utils/seo';
import mixpanelSSTrack from '~/hooks/useMixpanelSS';

export const meta: MetaFunction = ({ data }: any) => {
  return [
    { title: data.translations.title },
    { tagName: 'link', rel: 'canonical', href: data.baseUrl + '/' + data.locale },
    { tagName: 'link', rel: 'alternate', hrefLang: 'en', href: data.baseUrl + '/en' },
    { tagName: 'link', rel: 'alternate', hrefLang: 'fr', href: data.baseUrl + '/fr' },
    { tagName: 'link', rel: 'alternate', hrefLang: 'x-default', href: data.baseUrl + '/en' },
    { tagName: 'link', rel: 'icon', sizes: '32x32', href: data.baseUrl + '/favicon-32x32.png' },
    { tagName: 'link', rel: 'icon', sizes: '16x16', href: data.baseUrl + '/favicon-16x16.png' },
    { tagName: 'link', rel: 'mask-icon', href: data.baseUrl + '/safari-pinned-tab.svg', color: '#1c1a1f' },
    { tagName: 'link', rel: 'manifest', href: data.baseUrl + '/site.webmanifest' },
    { name: 'google', content: 'notranslate' },
    { name: 'msapplication-TileColor', content: '#1c1a1f' },
    { name: 'og:site_name', content: 'Fiveoffices' },
    { name: 'og:type', content: 'website' },
    { name: 'og:title', content: data.translations.title },
    { name: 'twitter:title', content: data.translations.title },
    { name: 'description', content: data.translations.description },
    { name: 'og:description', content: data.translations.description },
    { name: 'twitter:description', content: data.translations.description },
    { name: 'twitter:card', content: data.translations.title },
    { name: 'locale', content: data.locale },
    { name: 'twitter:image', content: data.baseUrl + '/media/content-images/office_2.jpg' },
    { name: 'og:locale', content: data.locale },
    { name: 'og:image', content: data.baseUrl + '/media/content-images/office_2.jpg' },
    { name: 'og:image:url', content: data.baseUrl + '/media/content-images/office_2.jpg' },
    { name: 'og:image:width', content: 1600 },
    { name: 'og:image:height', content: 1333 },
    { 'script:ld+json': getJsonLd(data.locale) },
  ];
};

export const loader: LoaderFunction = async ({ request, params }: LoaderFunctionArgs) => {
  const cookieHeader = request.headers.get('Cookie');
  const token = tokenStringFromCookieHeader(cookieHeader || '');
  const { data } = await axiosInstance.get<{
    offices: OfficeListType;
  }>('/api/v2/offices/display_office_sections', {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  });

  const t = await i18next.getFixedT(params.lang || DEFAULT_LANGUAGE);
  const translations = {
    title: t('metaTags.SeoTags.title'),
    description: t('metaTags.SeoTags.description'),
  };
  return {
    ...data,
    translations: translations,
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    locale: params.lang,
  };
};

const ACTIVE_LOWER_CAROUSEL_RANKS = ['4', '5', '6'];

export default function HomeIndex() {
  const { t } = useTranslation();
  const { display_office_sections } = useLoaderData() as { display_office_sections: DisplayOfficeSectionsType };

  const featuredOffices = display_office_sections[0];
  const lowerOfficeDisplaySections = Object.entries(display_office_sections)
    .filter(([key]) => ACTIVE_LOWER_CAROUSEL_RANKS.includes(key))
    .sort(([keyA], [keyB]) => Number(keyA) - Number(keyB));

  const navigate = useNavigate();
  // authenticator.authenticate();

  useEffect(() => {
    mixpanelTrack('0.hp_load');
    mixpanelSSTrack('0.hp_load_sst');
  }, []);

  return (
    <>
      <HomeNavbarWrapper />
      <main className="mt-12">
        <section className="container fixed-container-md mb-24">
          <OfficeCarousel
            offices={featuredOffices}
            title={t('landing.displaySections.section0.title')}
            url={t('landing.displaySections.section0.path')}
            onCardClick={() => mixpanelTrack('0.office_card_click')}
          >
            <LoaderButton
              btnText={t('landing.displaySections.section0.btnLabel')}
              variant="primary"
              size="xl"
              iconType="long-arrow-right"
              to={t('landing.displaySections.section0.path')}
              onClick={() => {
                mixpanelTrack('0.see_offices_main');
                navigate(t('landing.displaySections.section0.path'));
              }}
            />
          </OfficeCarousel>
        </section>

        <section className="container d-flex justify-content-center py-16 mb-8">
          <DNA />
        </section>

        <section className="container fixed-container-md mb-30">
          <Clients />
        </section>

        <section className="container fixed-container-md mb-30">
          <HowItWorks />
        </section>

        <section className="container fixed-container-md mb-30">
          <Advantages />
        </section>

        <section className="mb-34">
          <Testimonials />
        </section>

        <section className="container fixed-container-md mb-30">
          <HostFocus />
        </section>

        <section className="container fixed-container-md mb-24">
          {lowerOfficeDisplaySections.map(([key, offices]) => (
            <OfficeCarousel
              extraClasses="mb-30"
              key={key}
              offices={offices}
              title={t(`landing.displaySections.section${key}.title`)}
              url={t(`landing.displaySections.section${key}.path`)}
              hiddenTitle={true}
              onCardClick={() => mixpanelTrack('0.office_card_click')}
            >
              <LoaderButton
                btnText={t(`landing.displaySections.section${key}.btnLabel`)}
                variant="primary"
                size="xl"
                iconType="long-arrow-right"
                onClick={() => {
                  mixpanelTrack(`0.office_card_click_Ostrip_${key}`);
                  navigate(t(`landing.displaySections.section${key}.path`));
                }}
              />
            </OfficeCarousel>
          ))}
        </section>

        <section className="container fixed-container-md mb-30">
          <Press />
        </section>

        <section
          className="container fixed-container-sm mb-20 mb-lg-24"
          itemScope={true}
          itemType={'https://schema.org/FAQPage'}
        >
          <Faq />
        </section>
      </main>
      <footer className="container-fluid p-0">
        <Footer />
      </footer>
    </>
  );
}
