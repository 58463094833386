import { useTranslation } from 'react-i18next';

const DNA = () => {
  const { t } = useTranslation();

  return (
    <div className="dna-card bg-accent-500 rounded-3 py-12 px-6 px-sm-17 px-md-27">
      <p>
        <span>{t('landing.dna.textBeforeHighlight')} </span>
        <br className="d-none d-sm-block" />
        <span className="highlight">{t('landing.dna.textHighlight')}</span>
      </p>
    </div>
  );
};

export default DNA;
