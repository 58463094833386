import { useTranslation } from 'react-i18next';

import HeroSectionB from './HeroSectionB';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="container pt-14 pt-sm-18 mb-22 z-2">
      <div className="hero-center mx-auto">
        <h1 className="hero-title mb-8 mb-md-12">
          <span className="fo-open-sauce">{t('landing.hero.titleBeforeHighlight')} </span>
          <br className="d-lg-none" />
          <span className="fo-highlight-gradient -accent-line-highlight -italic  fo-open-sauce">
            {t('landing.hero.titleHighlight')}
          </span>
          <br />
          <span className="fo-open-sauce"> {t('landing.hero.titleAfterHighlight')}</span>
        </h1>

        <HeroSectionB />
      </div>
    </div>
  );
};

export default Hero;
