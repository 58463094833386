import { useNavigate } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import LoaderButton from '~/components/common/buttons/LoaderButton';
import mixpanelTrack from '~/hooks/useMixPanel';

const HowItWorks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="how-it-works overflow-hidden">
      <h2 className="fo-display-2 text-center mb-4">{t('landing.howItWorks.title')}</h2>
      <p className="subtitle text-center mb-14 mx-auto">{t('landing.howItWorks.subtitle')}</p>
      <div className="position-relative row g-10 g-md-23 mb-14">
        <div className="col-12 col-md-4 d-flex flex-column">
          <img
            className="mb-4 mb-md-9 align-self-center"
            src="/media-remix/icons/select-rate.svg"
            alt="Rate icon"
          />

          <h3
            className="text-center fo-display-3"
            dangerouslySetInnerHTML={{ __html: t('landing.howItWorks.step1.title') }}
          ></h3>
          <p className="text-center">{t('landing.howItWorks.step1.description')}</p>
        </div>

        <img
          className="connector-1"
          src="/media-remix/icons/how-it-works-connector-1.svg"
          alt="Rate icon"
        />

        <div className="col-12 col-md-4  d-flex flex-column">
          <img
            className="mb-4 mb-md-9 align-self-center"
            src="/media-remix/icons/puzzle.svg"
            alt="Rate icon"
          />

          <h3
            className="text-center fo-display-3"
            dangerouslySetInnerHTML={{ __html: t('landing.howItWorks.step2.title') }}
          ></h3>
          <p className="text-center">{t('landing.howItWorks.step2.description')}</p>
        </div>

        <img
          className="connector-2"
          src="/media-remix/icons/how-it-works-connector-2.svg"
          alt="Rate icon"
        />

        <div className="col-12 col-md-4  d-flex flex-column">
          <img
            className="mb-4 mb-md-9 align-self-center"
            src="/media-remix/icons/card.svg"
            alt="Rate icon"
          />

          <h3
            className="text-center fo-display-3"
            dangerouslySetInnerHTML={{ __html: t('landing.howItWorks.step3.title') }}
          ></h3>
          <p className="text-center">{t('landing.howItWorks.step3.description')}</p>
        </div>
      </div>
      <div className="d-grid d-md-flex justify-content-md-center mt-14">
        <LoaderButton
          btnText={t('landing.seeAllOffices.label')}
          variant="primary"
          size="xl"
          iconType="long-arrow-right"
          onClick={() => {
            mixpanelTrack('0.see_offices_secondary');
            navigate(t('landing.seeAllOffices.linkPath'));
          }}
        />
      </div>
    </div>
  );
};

export default HowItWorks;
