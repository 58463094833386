import { useTranslation } from 'react-i18next';
import mixpanelTrack from '~/hooks/useMixPanel';
import LoaderButton from '~/components/common/buttons/LoaderButton';

const HeroSectionB = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="d-flex justify-content-center">
          <LoaderButton
            btnText={t('landing.hero.buttons.seeOurOffices.label')}
            variant="accent"
            size="xl"
            iconType="search"
            to={t('landing.hero.buttons.seeOurOffices.linkPath')}
            onClick={() => {
              mixpanelTrack('0.see_offices_hero');
            }}
          />
        </div>

        <div className="d-flex flex-wrap gap-7 justify-content-center mt-6 mt-md-10">
          <div className="bullet-point d-flex align-items-center">
            <img
              className="me-4"
              src="/media-remix/icons/hero-seats.svg"
              alt="Seats icon"
            />
            <p className="text-2 -medium text-nowrap">{t('landing.hero.bullet1')}</p>
          </div>

          <div className="bullet-point d-flex align-items-center">
            <img
              className="me-4"
              src="/media-remix/icons/hero-contract.svg"
              alt="Contract icon"
            />
            <p className="text-2 -medium text-nowrap">{t('landing.hero.bullet2')}</p>
          </div>

          <div className="bullet-point d-flex align-items-center">
            <img
              className="me-4"
              src="/media-remix/icons/hero-commission.svg"
              alt="Commission icon"
            />
            <p className="text-2 -medium text-nowrap">{t('landing.hero.bullet3')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionB;
